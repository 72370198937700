import React from 'react';
import { HomeViewV2 } from 'views/rebranded-module/HomeViewV2';
import {
  mapNamedLocaleToLocale,
  subdomainLocalesArr,
} from 'lib/data/localesMap';
import generateHreflang from 'lib/utils/generateHreflang';
import PATHS from 'lib/navigation/paths';

import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import { HydrationWrapper } from 'views/common/components';
import handleHomeSSR from 'views/HomeView/utils/handleHomeSSR';
import { useSelector } from 'react-redux';

const Home = (props) => {
  const { serverData, initWidgetData, renderErrorPage } = props;
  const { isCrawler } = useSelector((state) => state.globalData);
  const handleErrorResponse = useErrorsHandler();
  if (renderErrorPage) {
    handleErrorResponse({}, false);
  }
  if (isCrawler) {
    return (
      <HydrationWrapper>
        <HomeViewV2 serverData={serverData} initWidgetData={initWidgetData} />
      </HydrationWrapper>
    );
  }
  return <HomeViewV2 serverData={serverData} initWidgetData={initWidgetData} />;
};
export async function getServerSideProps(ctx) {
  if (!subdomainLocalesArr.includes(ctx.locale)) {
    const { urls: homePageUrls } = generateHreflang(
      PATHS.homeLocalized().url,
      'en',
    );
    const { url: urlToRedirectTo } = homePageUrls.find(
      (url) => url.hreflang === mapNamedLocaleToLocale[ctx.locale],
    );
    return {
      redirect: {
        destination: `${urlToRedirectTo}${
          ctx.req.url.split('?')[1] ? `?${ctx.req.url.split('?')[1]}` : ''
        }`,
        permanent: true,
      },
    };
  }

  const homeSSR = await handleHomeSSR(ctx);
  return homeSSR;
}
export default Home;
